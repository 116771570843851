import React, { useState, useEffect } from 'react';
import './ExerciseSelectionModal.css';
import { useAuth } from '../../AuthContext';

const ExerciseSelectionModal = ({ isOpen, onClose, exercises, onSelectExercise }) => {
  const { authFetch } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLineavivencia, setSelectedLineavivencia] = useState('');
  const [lineaVivencias, setLineaVivencias] = useState([]); // State for Linea Vivencias

  useEffect(() => {
    // Fetch LineaVivencia options from the API
    const fetchLineaVivencias = async () => {
      try {
        const data = await authFetch('/sesiones/api/linea_vivencias/');
        // Set default grado_asociacion to 0 for each option
        const vivenciasWithGrado = data.map(option => ({
          ...option,
          grado_asociacion: 0,
        }));
        setLineaVivencias(vivenciasWithGrado);
      } catch (error) {
        console.error('Error fetching LineaVivencias:', error);
      }
    };

    if (isOpen) {
      fetchLineaVivencias(); // Only fetch when the modal is open
    }
  }, [isOpen]);

  if (!isOpen) return null;

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle lineavivencia selection change
  const handleLineavivenciaChange = (event) => {
    setSelectedLineavivencia(event.target.value);
  };

  // Filter exercises based on search query and selected lineavivencia
  const filteredExercises = exercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedLineavivencia === '' || 
      (exercise.linea_vivencia !== null && exercise.linea_vivencia.toString() === selectedLineavivencia)) // Include based on selected filter
  );


  return (
    <div className="modal">
      <div className="modal-content wide-modal">
        <h2>Select an Exercise</h2>
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search exercises..."
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: '10px', padding: '5px', width: '100%' }} // Optional styles
        />

        {/* Lineavivencia Filter */}
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="lineavivencia-select">Filter by Lineavivencia: </label>
          <select
            id="lineavivencia-select"
            value={selectedLineavivencia}
            onChange={handleLineavivenciaChange}
            style={{ marginLeft: '5px' }}
          >
            <option value="">Todas</option>
            {lineaVivencias.map((vivencia) => (
              <option key={vivencia.id} value={vivencia.id}>
                {vivencia.name} {/* Assuming 'name' is the field containing the display name */}
              </option>
            ))}
          </select>
        </div>

        <div style={{ maxHeight: '500px', overflowY: 'auto' }}> {/* Add vertical scroll */}
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Ejercicio</th>
                <th>Última Vez</th>
              </tr>
            </thead>
            <tbody>
              {filteredExercises.length > 0 ? (
                filteredExercises.map((exercise, index) => (
                  <tr 
                    key={index} 
                    onClick={() => onSelectExercise(exercise)} 
                    style={{ cursor: 'pointer', backgroundColor: '#f9f9f9', borderBottom: '1px solid #ccc' }} // Optional styles
                  >
                    <td>{exercise.name}</td>
                    <td>{exercise.ult_fecha_ej}</td> {/* Display Última Vez here */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No exercises found.</td> {/* Message for no exercises */}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ExerciseSelectionModal;
