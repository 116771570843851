import React, { useState, useEffect } from 'react';
import './SessionCreationModal.css';
import { useAuth } from '../../AuthContext';

const SessionCreationModal = ({ isOpen, onClose, onSave }) => {
  const { authFetch } = useAuth();
  const [sessionName, setSessionName] = useState('');
  const [sessionDate, setSessionDate] = useState('');
  const [lineaVivencias, setLineaVivencias] = useState([]);

  useEffect(() => {
    // Fetch LineaVivencia options from the API
    const fetchLineaVivencias = async () => {
      try {
        const data = await authFetch('/sesiones/api/linea_vivencias/');
        // Set default grado_asociacion to 0 for each option
        const vivenciasWithGrado = data.map(option => ({
          ...option,
          grado_asociacion: 0,
        }));
        setLineaVivencias(vivenciasWithGrado);
      } catch (error) {
        console.error('Error fetching LineaVivencias:', error);
      }
    };

    if (isOpen) {
      fetchLineaVivencias(); // Only fetch when the modal is open
    }
  }, [isOpen]);

  const handleGradoChange = (index, grado) => {
    const updatedVivencias = lineaVivencias.map((vivencia, i) => {
      if (i === index) {
        return { ...vivencia, grado_asociacion: grado };
      }
      return vivencia;
    });
    setLineaVivencias(updatedVivencias);
  };

  const handleDisableLinea = (index) => {
    // Set grado_asociacion to 0 when disabled
    handleGradoChange(index, 0);
  };

  const handleSave = () => {
    // Save session with associated LineaVivenciaSesion and grado_asociacion values
    onSave({
      name: sessionName,
      primera_fecha: sessionDate,
      linea_vivencias: lineaVivencias,
    });
    onClose();
  };

  const renderStars = (grado, index) => {
    return [...Array(5)].map((_, starIndex) => (
      <span
        key={starIndex}
        className={grado > starIndex ? 'star-filled' : 'star-empty'}
        onClick={() => handleGradoChange(index, starIndex + 1)}
        style={{ cursor: 'pointer', fontSize: '20px' }}
      >
        ●
      </span>
    ));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Create New Session</h2>
        <form>
          <label htmlFor="session-name">Session Name:</label>
          <input
            type="text"
            id="session-name"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            required
          />
          
          <label htmlFor="session-date">Session Date:</label>
          <input
            type="date"
            id="session-date"
            value={sessionDate}
            onChange={(e) => setSessionDate(e.target.value)}
            required
          />

          <h3>Linea Vivencias</h3>
          {lineaVivencias.length === 0 ? (
            <p>Loading Linea Vivencias...</p>
          ) : (
            <table className="linea-vivencia-table">
              <thead>
                <tr>
                  <th>Linea Vivencia</th>
                  <th>Rating</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lineaVivencias.map((vivencia, index) => (
                  <tr key={vivencia.id} className="linea-vivencia-entry">
                    <td>{vivencia.name}</td>
                    <td>
                      <div className="stars">
                        {renderStars(vivencia.grado_asociacion, index)}
                      </div>
                    </td>
                    <td>
                      {vivencia.grado_asociacion > 0 && ( // Show 'X' only if grado_asociacion > 0
                        <button
                          type="button"
                          onClick={() => handleDisableLinea(index)}
                          style={{ 
                            color: 'red', 
                            background: 'none', 
                            border: 'none', 
                            fontSize: '20px', 
                            cursor: 'pointer' 
                          }}
                        >
                          &#10006; {/* This is the HTML code for a red 'X' */}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className="modal-actions">
            <button type="button" onClick={handleSave}>Save Session</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SessionCreationModal;
