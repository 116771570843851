export const playSong = async (trackName, artistName, spotifyId, accessToken) => {
  try {
    let trackId = spotifyId;

    // Perform search only if spotifyId is not provided
    if (!trackId) {
      const searchResponse = await fetch(
        `https://api.spotify.com/v1/search?q=track:${encodeURIComponent(trackName)}%20artist:${encodeURIComponent(artistName)}&type=track&limit=1`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const searchData = await searchResponse.json();

      if (searchData.tracks.items.length > 0) {
        trackId = searchData.tracks.items[0].id;
      } else {
        console.log('No track found');
        return;
      }
    }

    const playResponse = await fetch(
      `https://api.spotify.com/v1/me/player/play`,
      {
        method: 'PUT',
        body: JSON.stringify({
          uris: [`spotify:track:${trackId}`],
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!playResponse.ok) {
      console.error('Failed to play the song');
    }
  } catch (error) {
    console.error('Error searching or playing the song:', error);
  }
};

export const createSpotifyPlaylist = async (sessionName, exercises, accessToken) => {
  const userProfileUrl = 'https://api.spotify.com/v1/me';
  const createPlaylistUrl = `https://api.spotify.com/v1/users/{user_id}/playlists`;
  const addTracksUrl = (playlistId) => `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;

  try {
    // Get the current user's profile to retrieve the user ID
    const profileResponse = await fetch(userProfileUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const profileData = await profileResponse.json();
    const userId = profileData.id;

    // Create a new playlist
    const createPlaylistResponse = await fetch(createPlaylistUrl.replace('{user_id}', userId), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: sessionName,
        description: 'Playlist created from exercises',
        public: false,
      }),
    });

    const playlistData = await createPlaylistResponse.json();
    const playlistId = playlistData.id;

    // Add tracks to the new playlist
    // Initialize an array of the same length as exercises to hold URIs
    let uris = new Array(exercises.length).fill(null);

    // Collect the URIs from all selected music, ensuring to handle cases where musica_ejercicio is null
    for (let i = 0; i < exercises.length; i++) {
      const exercise = exercises[i];
      const music = exercise.musica_ejercicio?.musica;
      
      if (music && music.spotify_id) {
        uris[i] = `spotify:track:${music.spotify_id}`;  // Maintain the order by setting the URI at the current index
      }
    }

    // For undefined URIs, attempt to search for the track on Spotify
    for (let i = 0; i < exercises.length; i++) {
      const exercise = exercises[i];
      const music = exercise.musica_ejercicio?.musica;
      
      if (music && !music.spotify_id) {
        try {
          const searchResult = await searchTrackOnSpotify(music.name, music.interprete.name, accessToken);
          
          if (searchResult && searchResult.uri) {
            uris[i] = searchResult.uri;  // Keep the URI in the correct order in the array
          } else {
            console.warn(`Could not find URI for ${music.name} by ${music.interprete.name}`);
          }
        } catch (error) {
          console.error(`Error searching for ${music.name} by ${music.interprete.name}:`, error);
        }
      }
    }

    // Filter out any null values from uris and join them into a comma-separated string
    // let urisString = uris.filter(Boolean).join(',');
    let filteredUris = uris
      .filter(Boolean)
      // .map(uri => uri.split(':').pop())  // Extract the last part of the URI
      // .join(', ');

    await fetch(addTracksUrl(playlistId), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        position: 0,
        uris: filteredUris,
      }),
    });
  } catch (error) {
    console.error('Error creating playlist or adding tracks:', error);
    throw error;
  }
};

// spotify.js

export const searchTrackOnSpotify = async (trackName, artistName, accessToken, spotifyId = null) => {
  // Use provided spotifyId if available
  if (spotifyId) {
    return { id: spotifyId, uri: `spotify:track:${spotifyId}` };
  }

  // Otherwise, perform the search
  const searchQuery = `${trackName} ${artistName}`;
  const searchUrl = `https://api.spotify.com/v1/search?q=${encodeURIComponent(searchQuery)}&type=track&limit=1`;

  try {
    const response = await fetch(searchUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error searching for track');
    }

    const data = await response.json();
    if (data.tracks && data.tracks.items.length > 0) {
      return data.tracks.items[0]; // Return the first track from the search results
    } else {
      return null; // No matching track found
    }
  } catch (error) {
    console.error('Error searching Spotify:', error);
    return null;
  }
};
