import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Load token from localStorage on app start
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  // Login function
  const login = async (username, password) => {
    const response = await fetch(`${apiUrl}/api-token-auth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();

    if (response.ok) {
      localStorage.setItem('token', data.token);
      setToken(data.token);
      return data;
    } else {
      throw new Error(data.non_field_errors || 'Login failed');
    }
  };

  // Logout function
  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  // AuthFetch function
  const authFetch = async (endpoint, { method = 'GET', body = null, params = {} } = {}) => {
    // Convert params object to URL query string
    const queryString = new URLSearchParams(params).toString();
    const fetchUrl = `${apiUrl}${endpoint}${queryString ? `?${queryString}` : ''}`;
    

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      ...(body && { body: JSON.stringify(body) }),
    };

    const response = await fetch(fetchUrl, options);
    if (!response.ok) {
      if (response.status === 401) {  // Check if the error is due to an invalid token
        console.log('logout')
        logout();  // Log out and redirect to login
      }
      const error = await response.json();
      throw new Error(error.detail || 'An error occurred');
    }
    return await response.json();
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, authFetch }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
