// src/components/MusicTabs.js
import React, { useState, useEffect } from 'react';
import MusicTable from './MusicTable';
import './MusicTabs.css'; // Create a CSS file for tab styles
import { useAuth } from '../../AuthContext';
import { debounce } from 'lodash'; // Install lodash for debouncing

const MusicTabs = ({ selectedExercise, selectedMusicDetails, handleSort, accessToken, handleSelectMusicExercise, playSong }) => {
  const { authFetch } = useAuth();
  const [activeTab, setActiveTab] = useState('Tab1');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMusicDataForTab2, setFilteredMusicDataForTab2] = useState([]);

  const musicDataForTab1 = selectedMusicDetails; // Data for the first tab

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Debounced search function to reduce API calls while typing
  const fetchMusicData = debounce(async (query) => {
    if (query) {
      try {
        const data = await authFetch(`/sesiones/api/musicas/search/`, {
          params: { search: query },
        });
        // Format the response data to include the selected exercise
        const formattedMusicData = data.map((music) => ({
          musica: music,
          ejercicio: selectedExercise.musica_ejercicio.ejercicio,
        }));

        setFilteredMusicDataForTab2(formattedMusicData);
      } catch (error) {
        console.error('Error fetching music data:', error);
      }
    } else {
      setFilteredMusicDataForTab2([]); // Clear results if search is empty
    }
  }, 300);

  useEffect(() => {
    fetchMusicData(searchQuery);
    // Cleanup debounce on unmount
    return () => fetchMusicData.cancel();
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="music-tabs">
      <div className="tab-titles">
        <button className={activeTab === 'Tab 1' ? 'active' : ''} onClick={() => handleTabChange('Tab1')}>
          Músicas Ejercicio
        </button>
        <button className={activeTab === 'Tab 2' ? 'active' : ''} onClick={() => handleTabChange('Tab2')}>
          Buscar
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'Tab1' && (
          <MusicTable
            selectedExercise={selectedExercise}
            selectedMusicDetails={musicDataForTab1}
            handleSort={handleSort}
            accessToken={accessToken}
            handleSelectMusicExercise={handleSelectMusicExercise}
            playSong={playSong}
          />
        )}
        {activeTab === 'Tab2' && (
          <div>
            <input
              type="text"
              placeholder="Search musicas..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-box"
            />
            <MusicTable
              selectedExercise={selectedExercise}
              selectedMusicDetails={filteredMusicDataForTab2}
              handleSort={handleSort}
              accessToken={accessToken}
              handleSelectMusicExercise={handleSelectMusicExercise}
              playSong={playSong}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicTabs;
