import React, { useEffect } from 'react';

const SpotifyAuthCheck = ({ accessToken, setAccessToken, setIsAuthenticated }) => {
  useEffect(() => {
    const checkSpotifyToken = async () => {
      if (!accessToken) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await fetch('https://api.spotify.com/v1/me', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (!response.ok) throw new Error('Invalid token');
      } catch (error) {
        console.error('Spotify token is invalid, re-authentication required:', error);
        setIsAuthenticated(false);
        localStorage.removeItem('spotifyAccessToken');
        setAccessToken(null);
      }
    };

    checkSpotifyToken();
  }, [accessToken, setAccessToken, setIsAuthenticated]);

  return null; // This component does not render anything
};

export default SpotifyAuthCheck;
