import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import SpotifyAuth from './components/SpotifyAuth';
// import SpotifyPlayer from './components/SpotifyPlayer';
import ElencoBrowser from './components/ElencoBrowser';
import SessionExplorer from './components/sesion/SessionExplorer';
import SessionBuilder from './components/sesion/SessionBuilder';
import Login from './components/Login';

const App = () => {
  const { token } = useContext(AuthContext);
  const [spotifyToken, setSpotifyToken] = useState(null);
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const scopes = process.env.REACT_APP_SCOPES;
  console.log(redirectUri)

  // Retrieve Spotify token from localStorage on initial load
  useEffect(() => {
    const storedToken = localStorage.getItem('spotifyAccessToken');
    if (storedToken) {
      setSpotifyToken(storedToken);
      validateSpotifyToken(storedToken); // Validate the token on load
    }
  }, []);

  // Function to validate the Spotify token
  const validateSpotifyToken = async (token) => {
    try {
      const response = await fetch('https://api.spotify.com/v1/me', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Invalid token'); // Handle the invalid token case
      }

      // Optionally, you can process the response here
      const userData = await response.json();
      console.log('User Data:', userData);
      
    } catch (error) {
      console.error(error);
      // Clear the token if it's invalid
      setSpotifyToken(null);
      localStorage.removeItem('spotifyAccessToken');
    }
  };

  // Update spotifyToken both in state and localStorage
  const handleSetSpotifyToken = (spotifyToken) => {
    setSpotifyToken(spotifyToken);
    localStorage.setItem('spotifyAccessToken', spotifyToken);
  };

  return (
    <div className="App">
      <h1>Crear Sesión</h1>
      <nav>
        <ul>
          <li>
            <Link to="/">Session Explorer</Link>
          </li>
          <li>
            <Link to="/elenco">Elenco Browser</Link>
          </li>
        </ul>
      </nav>
{/* token: {token}<br></br>
spotifyToken: {spotifyToken}<br></br> */}
        
        <>
          {!spotifyToken ? (
            <div>
              <p>You need to log in to Spotify:</p>
              <SpotifyAuth setSpotifyToken={handleSetSpotifyToken} />
            </div>
          ) : token? (
            <Routes>
              <Route path="/" element={<Navigate to="/sessions" />} />
              <Route path="/login" element={<Navigate to="/sessions" />} />
              <Route path="/elenco" element={<ElencoBrowser spotifyToken={spotifyToken} />} />
              <Route path="/sessions" element={<SessionExplorer />} />
              <Route path="/sesion/:sessionId" element={<SessionBuilder spotifyToken={spotifyToken} setSpotifyToken={handleSetSpotifyToken} />} />
            </Routes>
          ) : (
              <Routes>
                <Route path="*" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login token={token} spotifyToken={spotifyToken} setSpotifyToken={handleSetSpotifyToken} />} />
              </Routes>
          )
          }
        </>
      
    </div>
  );
};

// Wrap your app in Router for routing to work
const WrappedApp = () => (
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export default WrappedApp;

