import React, { useEffect, useState } from 'react';
// import './SpotifyPlayer.css'; // Add your custom styles here

const ElencoBrowser = ({ spotifyToken }) => {
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [musicDetails, setMusicDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search query

  useEffect(() => {
    // Fetch exercises data
    fetch('/cimeb_exercises_data.json')
      .then(response => response.json())
      .then(data => setExercises(data))
      .catch(error => console.error('Error fetching the JSON data:', error));
  }, []);

  const handleExerciseClick = (exercise) => {
    setSelectedExercise(exercise);
    setMusicDetails(exercise.details);
  };

  const playSong = async (trackName, artistName) => {
    try {
      const searchResponse = await fetch(
        `https://api.spotify.com/v1/search?q=track:${encodeURIComponent(trackName)}%20artist:${encodeURIComponent(artistName)}&type=track&limit=1`,
        {
          headers: {
            Authorization: `Bearer ${spotifyToken}`,
          },
        }
      );

      const searchData = await searchResponse.json();

      if (searchData.tracks.items.length > 0) {
        const trackId = searchData.tracks.items[0].id;

        const playResponse = await fetch(
          `https://api.spotify.com/v1/me/player/play`,
          {
            method: 'PUT',
            body: JSON.stringify({
              uris: [`spotify:track:${trackId}`],
            }),
            headers: {
              Authorization: `Bearer ${spotifyToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!playResponse.ok) {
          console.error('Failed to play the song');
        }
      } else {
        console.log('No track found');
      }
    } catch (error) {
      console.error('Error searching or playing the song:', error);
    }
  };

  // Function to handle search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered exercises based on the search query
  const filteredExercises = exercises.filter(exercise =>
    exercise.ejercicio.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="spotify-player">
      <div className="table-container">
        <div className="exercise-table">
          <h2>Sesión</h2>
          <input
            type="text"
            placeholder="Buscar ejercicio..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="exercise-search-box"
          />
          {filteredExercises.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Ejercicio</th>
                  <th>Última Vez</th>
                </tr>
              </thead>
              <tbody>
                {filteredExercises.map((exercise, index) => (
                  <tr key={index} onClick={() => handleExerciseClick(exercise)}>
                    <td>{exercise.ejercicio}</td>
                    <td>{exercise.ult_fecha_ej}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No exercises found</p>
          )}
        </div>
        <div className="music-table">
          {selectedExercise ? (
            <>
              <h2>Opciones de Música para {selectedExercise.ejercicio}</h2>
              <table>
                <thead>
                  <tr>
                    <th>Canción</th>
                    <th>Artista</th>
                    <th>Último Uso</th>
                    <th>Último Uso Ejercicio</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {musicDetails.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail.musica}</td>
                      <td>{detail.interprete}</td>
                      <td>{detail.ult_fecha_mus}</td>
                      <td>{detail.ult_fecha_mus_ej}</td>
                      <td>
                        <button
                          onClick={() => playSong(detail.musica.name, detail.interprete.name, detail.musica.spotify_id, spotifyToken)}
                        >
                          Play on Spotify
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>Select an exercise to view details</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElencoBrowser;

