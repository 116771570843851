import React from 'react';
import './DeleteConfirmationModal.css';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="delete-confirmation-modal">
      <h3>Are you sure you want to delete this exercise?</h3>
      <div className="delete-confirmation-modal-buttons">
        <button className="confirm-button" onClick={onConfirm}>Yes</button>
        <button className="cancel-button" onClick={onClose}>No</button>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
