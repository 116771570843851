import React from 'react';
import './ExerciseTable.css'; // Import CSS for highlighting
import { FaTrashAlt, FaPlay } from 'react-icons/fa'; // Font Awesome icons for deletion and play

const ExerciseTable = ({ exercises, onExerciseClick, selectedExercise, onDeleteExercise, playSong, accessToken }) => {
  return (
    <div>
      <h3>Exercise Table</h3>
      <table>
        <thead>
          <tr>
            <th>#</th> {/* Row number header */}
            <th>Ejercicio</th>
            <th>Musica</th>
            <th>Interprete</th>
            <th>Última Vez</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {exercises.map((exercise, index) => {
            const musicaEjercicio = exercise.musica_ejercicio;
            const ejercicio = musicaEjercicio?.ejercicio;

            // Check if the current exercise is the selected one
            const isSelected = selectedExercise && selectedExercise.musica_ejercicio.ejercicio.id === ejercicio.id;

            return (
              <tr 
                key={index} 
                onClick={() => onExerciseClick(exercise)}
                className={isSelected ? 'selected-exercise' : ''} // Add selected class if the exercise is selected
              >
                <td>{index + 1}</td> {/* Display row number */}
                <td>{ejercicio?.name || '-'}</td>
                <td>{musicaEjercicio?.musica?.name || '-'}</td>
                <td>{musicaEjercicio?.musica?.interprete?.name || '-'}</td>
                <td>{ejercicio?.ult_fecha_ej || '-'}</td>
                <td>
                  {/* Play button, only shown if there is assigned music */}
                  {musicaEjercicio?.musica && (
                    <FaPlay 
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click from triggering
                        playSong(musicaEjercicio.musica.name, musicaEjercicio.musica.interprete.name, musicaEjercicio.musica.spotify_id, accessToken);
                      }} 
                      style={{ cursor: 'pointer', color: 'green' }}
                    />
                  )}
                </td>
                <td>
                  <FaTrashAlt 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click from triggering
                      onDeleteExercise(exercise);
                    }} 
                    style={{ cursor: 'pointer', color: 'red' }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExerciseTable;
